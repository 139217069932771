/* eslint-disable sonarjs/no-duplicate-string */

import {
  HStack,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  Tooltip,
  type TextProps,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import type {
  AppsDefiDataItemType,
  AppsDefiDataOrderByType,
  OrderDirType,
} from '@kiroboio/fct-sdk';

import type { OrderByType } from '@kiroboio/fct-sdk/service/mp/defi';
import type { DeepReadonly } from '@kiroboio/fct-sdk/utils/data';
import { CiFaceFrown, CiFaceMeh, CiFaceSmile } from 'react-icons/ci';
import { useDefiAppsSocialModalActions } from '~/lib/hooks/useModals/useComplexModals';
import { TextOrShortened } from '../../general/TextOrShortened';
import { TradingTableTitle } from '../TradingTableTitle';
import { RouteTypes } from '../routeTypes';

type App = AppsDefiDataItemType;

const getColorByValue = (value: DeepReadonly<string | number> | undefined) => {
  const safeValue = Number(value || 0);
  if (safeValue > 0) {
    return 'green.400';
  }
  if (safeValue < 0) {
    return 'red.400';
  }
  return 'gray.400';
};

const isSentimentStrongUp = (value: number) => {
  return value <= 100 && value >= 90;
};
const isSentimentPositive = (value: number) => {
  return value < 90 && value >= 55;
};
const isSentimentNeutral = (value: number) => {
  return value < 55 && value > 45;
};
const isSentimentNegative = (value: number) => {
  return value <= 45 && value > 10;
};
const isSentimentStrongDown = (value: number) => {
  return value <= 10 && value >= 0;
};

const getSentimentStrongUpOrDown = (
  value: DeepReadonly<string | number> | undefined
) => {
  if (value === undefined) {
    return undefined;
  }
  const safeValue = Number(value || 0);
  const strongUp = isSentimentStrongUp(safeValue);
  const strongDown = isSentimentStrongDown(safeValue);
  if (strongUp) return 'strongUp';
  if (strongDown) return 'strongDown';
  return undefined;
};

export const getCalculatedSentimentPercent = (
  sentiment: DeepReadonly<string | number> | undefined
) => {
  return Number(sentiment || 0) * 2 - 100;
};

export const getSentimentText = (
  value: DeepReadonly<string | number> | undefined
) => {
  const safeValue = Number(value || 0);
  if (isSentimentStrongUp(safeValue) || isSentimentPositive(safeValue)) {
    return 'Positive';
  }
  if (isSentimentNeutral(safeValue)) {
    return 'Neutral';
  }
  if (isSentimentNegative(safeValue) || isSentimentStrongDown(safeValue)) {
    return 'Negative';
  }
  return 'n/a';
};

export const getSentimentColor = (
  value: DeepReadonly<string | number> | undefined
) => {
  const sentiment = getSentimentText(value);
  if (sentiment === 'Positive') {
    return 'green.400';
  }
  if (sentiment === 'Negative') {
    return 'red.400';
  }
  return 'orange.400';
};

interface TitlePropsWithSort extends TextProps {
  name: string;
  orderDir?: OrderDirType;
  orderBy?: OrderByType;
  onOrderChange?: (nextOrderBy: AppsDefiDataOrderByType) => void;
}
interface CellProps {
  app: App;
}

interface ITradingTokenComponents {
  view: 'table' | 'app';
}

export const isWithSecurityPassedSwitch = (route: RouteTypes) =>
  route === RouteTypes.hot_pairs || route === RouteTypes.new_pairs;
export const TOOL_WIDTH = '256px';

export const generateTradingTokenComponents = ({
  view,
}: ITradingTokenComponents) => [
  {
    key: 'pool',
    view,
    name: 'pool' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <Text color="gray.500" fontWeight="semibold" {...rest}>
          {t(name)}
        </Text>
      );
    },
    Cell({ app, ...rest }: CellProps) {
      const quoteTokenAddress = app?.raw.pool.quoteToken;
      const quoteTokenSymbol = app?.raw.underlyingTokens.find(
        (token) => token.address === quoteTokenAddress
      )?.symbol;

      const quoteToken = app?.fmt?.underlyingTokens?.find(
        (token) => token?.symbol === quoteTokenSymbol
      );
      const investeInToken = app?.fmt?.underlyingTokens?.find(
        (token) => token?.symbol !== quoteTokenSymbol
      );
      return (
        <HStack spacing="0" {...rest}>
          <TextOrShortened
            fontWeight="700"
            maxLength={8}
            text={investeInToken?.symbol || 'n/a'}
          />
          <Text color="gray.400">/{quoteToken?.symbol}</Text>
        </HStack>
      );
    },
  },
  {
    key: 'protocol',
    view,
    name: 'protocol' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <Text color="gray.500" fontWeight="semibold" {...rest}>
          {t(name)}
        </Text>
      );
    },
    Cell({ app }: CellProps) {
      return (
        <HStack spacing="2px">
          <Image
            src={app?.fmt?.pool?.logo}
            maxWidth="24px"
            maxHeight="24px"
            rounded="full"
            alt={app?.fmt?.pool?.name || 'n/a'}
          />

          <Text as="span">{app?.fmt?.pool?.name || 'n/a'}</Text>
        </HStack>
      );
    },
  },
  {
    key: 'investIn',
    view,
    name: 'invest-in' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <Text color="gray.500" fontWeight="semibold" {...rest}>
          {t(name)}
        </Text>
      );
    },
    Cell({ app }: CellProps) {
      const quoteToken = app?.raw.pool.quoteToken;
      const quoteTokenSymbol = app?.raw.underlyingTokens.find(
        (token) => token.address === quoteToken
      )?.symbol;

      const investeInToken = app?.fmt?.underlyingTokens?.find(
        (token) => token?.symbol !== quoteTokenSymbol
      );
      return (
        <HStack spacing="4px">
          <Image
            src={investeInToken?.thumbnail || ''}
            maxWidth="24px"
            maxHeight="24px"
            rounded="full"
            alt={investeInToken?.symbol || 'n/a'}
          />
          <TextOrShortened
            maxLength={16}
            text={investeInToken?.name || 'n/a'}
          />
        </HStack>
      );
    },
  },
  {
    key: 'age',
    view,
    name: 'age' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          orderByTitle="poolAge"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened maxLength={16} text={`${app?.fmt?.pool?.createdAt}`} />
      );
    },
  },
  {
    key: 'social-media',
    view,
    name: 'social-media' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          orderByTitle="sentiment"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      const { open: openDefiAppsSocial } = useDefiAppsSocialModalActions(
        app.raw.id
      );
      const handleOpenDefiAppsSocial = () => {
        openDefiAppsSocial({ dataItem: app, defiAppsRowId: app.raw.id, view });
      };

      const t = useTranslations();
      const sentiment = app?.raw?.socials?.lunarCrush?.sentiment;
      const hasSentiment = sentiment !== undefined;
      const sentimentText = hasSentiment
        ? getSentimentText(sentiment)
        : t('unavailable');
      const sentimentColor = hasSentiment
        ? getSentimentColor(sentiment)
        : 'gray.400';

      const sentimentStrongUpOrDown = getSentimentStrongUpOrDown(sentiment);
      const isStrongUpOrDown = sentimentStrongUpOrDown !== undefined;
      const isStrongUp =
        isStrongUpOrDown && sentimentStrongUpOrDown === 'strongUp';

      const calculatedPercent = getCalculatedSentimentPercent(sentiment);
      const percentDisplay = hasSentiment ? (
        <Stack>
          <Text color={sentimentColor}>{calculatedPercent}%</Text>
        </Stack>
      ) : undefined;

      return (
        <Tooltip
          label={percentDisplay}
          placement="bottom-start"
          aria-label="A tooltip"
          bg="whiteAlpha.400"
          _dark={{ bg: 'blackAlpha.400' }}
        >
          <HStack
            sx={
              view === 'app'
                ? undefined
                : {
                    '&:hover .more-social-info, &:focus-within .more-social-info':
                      {
                        opacity: 1,
                      },
                    '&:not(:hover) .more-social-info': {
                      opacity: 0,
                    },
                  }
            }
            minH={view === 'app' ? undefined : '36px'}
            minW={view === 'app' ? undefined : '152px'}
            spacing="5px"
          >
            <Text color={sentimentColor}>{sentimentText}</Text>
            {isStrongUpOrDown && (
              <Icon
                as={isStrongUp ? CiFaceSmile : CiFaceFrown}
                color={getSentimentColor(sentiment)}
              />
            )}
            {isSentimentNeutral(sentiment || 0) && (
              <Icon as={CiFaceMeh} color={getSentimentColor(sentiment)} />
            )}
            {hasSentiment && (
              <>
                <Spacer />
                <Text
                  className={view === 'app' ? undefined : 'more-social-info'}
                  opacity={view === 'app' ? '1' : '0'}
                  _focus={
                    view === 'app'
                      ? undefined
                      : {
                          opacity: 1,
                        }
                  }
                  fontSize="14px"
                  variant="link"
                  textDecoration="underline"
                  cursor="pointer"
                  _hover={{ color: 'blue.400' }}
                  onClick={(e) => {
                    if (!hasSentiment) return;
                    e.stopPropagation();
                    handleOpenDefiAppsSocial();
                  }}
                >
                  More Info
                </Text>
              </>
            )}
          </HStack>
        </Tooltip>
      );
    },
  },
  {
    key: 'liquidity',
    view,
    name: 'liquidity' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={t('the-amount-of-liquidity-available-in-the-pool')}
          orderByTitle="tvl"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened maxLength={10} text={`$${app?.fmt?.pool?.tvlUsd}`} />
      );
    },
  },
  {
    key: 'twentyFourHoursVolume',
    view,
    name: '24h-volume' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={t('the-total-volume-of-the-pool-in-the-last-24-hours')}
          orderByTitle="volume"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened
          maxLength={10}
          text={`$${app?.fmt?.pool?.volumeUsd}`}
        />
      );
    },
  },

  {
    key: 'fiveMinutesPrice',
    view,
    name: '5-min' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={`${t(
            'the-percentage-change-in-value-of-the-token-over-the-past'
          )} ${t('5-minutes-0')}`}
          orderByTitle="price5m"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened
          color={getColorByValue(app?.raw?.priceChange?.fiveMin)}
          maxLength={10}
          text={`${app?.fmt?.priceChange?.fiveMin}`}
        />
      );
    },
  },

  {
    key: 'oneHourPrice',
    view,
    name: '1-hour' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={`${t(
            'the-percentage-change-in-value-of-the-token-over-the-past'
          )} ${t('1-hour-0')}`}
          orderByTitle="price1h"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened
          color={getColorByValue(app?.raw?.priceChange?.oneHour)}
          maxLength={10}
          text={`${app?.fmt?.priceChange?.oneHour}`}
        />
      );
    },
  },
  {
    key: 'sixHoursPrice',
    view,
    name: '6-hours' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={`${t(
            'the-percentage-change-in-value-of-the-token-over-the-past'
          )} ${t('6-hours-0')}`}
          orderByTitle="price6h"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened
          color={getColorByValue(app?.raw?.priceChange?.sixHours)}
          maxLength={10}
          text={`${app?.fmt?.priceChange?.sixHours}`}
        />
      );
    },
  },
  {
    key: 'oneDayPrice',
    view,
    name: '1-day' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={`${t(
            'the-percentage-change-in-value-of-the-token-over-the-past'
          )} ${t('1-day-0')}`}
          orderByTitle="price1d"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened
          color={getColorByValue(app?.raw?.priceChange?.oneDay)}
          maxLength={10}
          text={`${app?.fmt?.priceChange?.oneDay}`}
        />
      );
    },
  },
  {
    key: 'sevenDaysPrice',
    view,
    name: '7-days' as const,
    Title({
      name,
      orderBy,
      orderDir,
      onOrderChange,
      ...rest
    }: TitlePropsWithSort) {
      const t = useTranslations();
      return (
        <TradingTableTitle
          title={t(name)}
          description={`${t(
            'the-percentage-change-in-value-of-the-token-over-the-past'
          )} ${t('7-days-0')}`}
          orderByTitle="price7d"
          orderBy={orderBy}
          orderDir={orderDir}
          onOrderChange={onOrderChange}
          {...rest}
        />
      );
    },
    Cell({ app }: CellProps) {
      return (
        <TextOrShortened
          color={getColorByValue(app?.raw?.priceChange?.sevenDays)}
          maxLength={10}
          text={`${app?.fmt?.priceChange?.sevenDays}`}
        />
      );
    },
  },
];

export const MIN_TABLE_WIDTH = `${112 * generateTradingTokenComponents({ view: 'table' }).length}px`;
